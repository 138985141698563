import { createGlobalStyle } from 'styled-components'

const GlobalStyle = createGlobalStyle`
* {
  font-family: 'Montserrat', sans-serif;
}
  body {
    min-height: 100vh;
    background-image: linear-gradient(#FFFFFF, #1f4788);
  }
`

export default GlobalStyle
