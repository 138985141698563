import React from 'react'
import styled from 'styled-components'

import { ReactComponent as Discord } from './images/discord.inline.svg'
import { ReactComponent as Twitter } from './images/twitter.inline.svg'
import { ReactComponent as Github } from './images/github.inline.svg'
import { ReactComponent as Telegram } from './images/telegram.inline.svg'
import { ReactComponent as Gitbook } from './images/gitbook.svg'

const StyledFooter = styled.footer`
  width: 100%;
  margin: 0 auto 0 auto;
  display: flex;
  justify-content: center;
  position: center;
  align-items: center;
  padding: 30px;
  @media (max-width: 960px) {
    padding: 1rem;
    margin-left: auto;
    left-right: auto;
  }
`

const StyledSocialRow = styled.nav`
  display: flex;
  flex-direction: row;
  align-items: center;
  margin: 0 auto 0 auto;
  & > *:not(:first-of-type) {
    margin-top: 0;
    margin-left: 20px;
  }
  @media (max-width: 640px) {
    margin-top: 0.5rem;
  `

const StyledTwitter = styled(Twitter)`
  path {
    fill: #FFFFFF;
  }
  width: 20px;
  height: 20px;
`

const StyledDiscord = styled(Discord)`
  path {
    fill: #FFFFFF;
  }
  width: 22px;
  height: 22px;
`

const StyledGithub = styled(Github)`
  path {
    fill: #FFFFFF;
  }
  width: 20px;
  height: 20px;
`

const StyledTelegram = styled(Telegram)`
  path {
    fill: #FFFFFF;
  }
  margin-top: -1rem;
  width: 20px;
  height: 20px;
`

const StyledGitbook = styled(Gitbook)`
  path {
    fill: #FFFFFF;
  }
  width: 22px;
  height: 22px;
`

const Footer: React.FC = () => {
  return (
    <StyledFooter>
      <StyledSocialRow>
      <a target="_blank" rel="noreferrer" href="https://twitter.com/GREENHOUSEDEX">
            <StyledTwitter />
          </a>
          <a target="_blank" rel="noreferrer" href="https://discord.gg/F6hF8Z9vRt">
            <StyledDiscord />
          </a>
          <a target="_blank" rel="noreferrer" href="https://t.me/greenhouseoffical">
            <StyledTelegram />
          </a>
          <a target="_blank" rel="noreferrer" href="https://docs.greenhousedex.com/">
            <StyledGitbook />
          </a>
          </StyledSocialRow>
    </StyledFooter>
  )
}
export default Footer